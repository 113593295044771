<template>
  <div id="view-calling-card-pool" class="inventory-list-container">

    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'CallingCardsPool'">
      <template v-slot:right-end>
        <div class="flex flex-row space-x-2">
<!--          <vs-button class="alpha-clear" v-if="selectedAlpha" color="warning" type="flat" icon-pack="feather" icon="icon-x" size="small" @click="clearSelectedAlpha()"></vs-button>-->
<!--          <vs-pagination-modified v-model="selectedAlphaCurrentX" :total="26" :max="26" v-on:change="pageChanged"></vs-pagination-modified>-->
          <div class="p-3"></div>
          <vs-button @click="openAddPool" type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddPool') }}
          </vs-button>
          <vs-button to="/inventory/pool/add" type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddPoolInventory') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

    <!--    Empty List State -->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="callingCardsPool.length === 0 && !errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
        </div>
      </div>
    </transition>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <vs-table-modified v-show="callingCardsPool.length > 0 && !errorFetching" ref="table"
                search
                         v-on:selected="viewPoolPAD"
                :data="callingCardsPool">

        <template slot="thead">
          <vs-th >{{$t('Date')}}</vs-th>
          <vs-th >{{$t('PoolName')}}</vs-th>
          <vs-th >{{$t('NoOfProducts')}}</vs-th>
          <vs-th>{{ $t('UnSoldPins') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

            <vs-td>
              <p class="font-medium truncate">{{ tr.created | dateFormat }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium truncate">{{ tr.pool_name }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium truncate">{{ tr.no_of_products }}</p>
            </vs-td>

            <vs-td>
              <p class="font-medium truncate">{{ tr.no_of_pins_unsold }}</p>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>

      </vs-table-modified>
    </transition>

    <div class="fixed inset-0 overflow-y-auto" v-if="showAddPool" style="z-index: 80000;">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  {{ $t('AddNewPool') }}
                </h3>
                  <div class="mb-1 mt-2 sm:mb-2 w-full">
                    <label for="firstName" class="inline-block mb-1 font-medium">{{ $t('PoolName') }}<span class="text-danger">*</span></label>
                    <input
                      placeholder=""
                      required=""
                      type="text"
                      v-model="poolName"
                      class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                      id="firstName"
                      name="firstName"
                    />
                </div>
                <div class="flex flex-row space-x-2">
                  <div class="mt-4 mb-2 w-full md:w-1/2 sm:mb-4 mx-auto">
                    <button
                      @click="addPool"
                      type="submit"
                      :disabled="poolName === ''"
                      class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                      :class="poolName === '' ? 'bg-gray-600' : 'bg-gray-900  hover:bg-gray-800'"
                    >
                      {{ $t('AddPool') }}
                    </button>
                  </div>

                  <div class="mt-4 mb-2 w-full md:w-1/2 sm:mb-4 mx-auto">
                    <button
                      @click="cancelPoolPop"
                      type="submit"
                      class="bg-red-600 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"

                    >
                      {{ $t('Cancel') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ViewInventoryPool',
  data() {
    return {
      isMounted: false,
      errorFetching: false,
      showAddPool: false,
      poolName: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Calling Cards Pool', i18n: 'CallingCardsPool', active: true },
      ],
    }
  },
  computed: {
    callingCardsPool() {
      return this.$store.state.callingCards.callingCardsPool
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.callingCardsPool.length
    },
  },
  methods: {
    viewPoolPAD(pool) {
      // pool_id
      this.$router.push({name: 'inventory-pool-pad', params: {poolId: pool.pool_id}})
    },
    cancelPoolPop() {
      this.poolName = '';
      this.showAddPool = false;
    },
    openAddPool() {
      this.showAddPool = true;
    },
    addPool() {
      this.$vs.loading()
      this.showAddPool = false;
      this.$store.dispatch('callingCards/addCallingCardPool', {
        payload: {pool_name: this.poolName}
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.fetchCallingCardsPools()
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCallingCardsPools(isMore = false) {
      this.$vs.loading()
      this.$store.dispatch('callingCards/fetchCallingCardPools', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.fetchCallingCardsPools()
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
  #view-calling-card-pool {
    .product-image {
      /*max-width: 200px;*/
      max-height: 40px;
      object-fit: cover;
    }
    .supplier-image {
      /*max-width: 200px;*/
      max-height: 40px;
      object-fit: cover;
    }

    .vs-con-table {

      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .inventory-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .supplier-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
